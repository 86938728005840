import thumbnails3dModule from '../thumbnails3dModule';

function threeDThumbnailController($scope) {
  'ngInject';

  const ctrl = this;
  $scope.isLoading = false;
  ctrl.setLoading = state => {
    $scope.isLoading = state;
  };
}

thumbnails3dModule.controller('3dThumbnailController', threeDThumbnailController);

export default threeDThumbnailController;
