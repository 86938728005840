import THREE, {
  DomElement,
  WebGLScene,
  WebGLCamera,
  WebGLRenderer,
  WebGLJson,
  WebGLMTLs,
  WebGLOrbitControls
} from 'THREE';
import TWEEN from 'TWEEN';
import {
  ThumbnailRenderers,
  MaxRenderDistanceDefault,
  MinRenderDistanceDefault
} from '../constants/thumbnail3dConstant';
import thumbnailUntil from './thumbnail3dUtil';

const { addLightsToScene } = thumbnailUntil;

const renderers: ThumbnailRenderers = {};

// Some weird dependency system problems need to be bypassed
const THREE = window.THREE as unknown as THREE;

const getRenderer = (key: string, container: DomElement) => {
  if (!renderers[key]) {
    renderers[key] = {
      container,
      renderer: new THREE.WebGLRenderer({ antialias: true, alpha: true })
    };
  }
  return renderers[key].renderer;
};

const containerWidth = (container: DomElement) => {
  return container.parentElement?.offsetWidth;
};

const containerHeight = (container: DomElement) => {
  return container.parentElement?.offsetHeight;
};

const render = (renderer: WebGLRenderer, scene: WebGLScene, camera: WebGLCamera) => {
  renderer.render(scene, camera);
};

const initializeControls = (
  renderer: WebGLRenderer,
  scene: WebGLScene,
  camera: WebGLCamera,
  container: DomElement,
  json: WebGLJson
) => {
  // The controller that lets us spin the camera around an object
  const orbitControls = new THREE.OrbitControls(camera, container, json, 'static');

  orbitControls.rotateSpeed = 1.5;
  orbitControls.zoomSpeed = 1.5;
  orbitControls.dampingFactor = 0.3;
  orbitControls.addEventListener('change', () => render(renderer, scene, camera));
  return orbitControls;
};

const animate = (
  controls: WebGLOrbitControls,
  renderer: WebGLRenderer,
  scene: WebGLScene,
  camera: WebGLCamera
) => {
  if (controls.enabled) {
    controls.update();
  }

  TWEEN.update();
  render(renderer, scene, camera);
  requestAnimationFrame(() => animate(controls, renderer, scene, camera));
};

const createCanvas = (renderer: WebGLRenderer, container: DomElement, camera: WebGLCamera) => {
  const setRendererSize = () => {
    camera.aspect = containerWidth(container) / containerHeight(container);
    camera.updateProjectionMatrix();
    renderer.setSize(containerWidth(container), containerHeight(container));
  };

  renderer.setSize(containerWidth(container), containerHeight(container));
  const canvas = renderer.domElement;
  let resizeTimer = 0;
  window.addEventListener('resize', () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(setRendererSize, 100);
  });
  window.addEventListener('beforeunload', () => {
    // canvas goes black when navigating to another page
    canvas.style.display = 'none';
  });
  return canvas;
};

const loadObjAndMtl3D = (
  targetId: number,
  container: DomElement,
  json: WebGLJson,
  useDynamicLighting: boolean
) => {
  const rendererKey = `THREE_renderer_targetId_${targetId}`;
  const renderer = getRenderer(rendererKey, container);

  const calculatedMaxRenderDistance =
    new THREE.Vector3(json.aabb.max.x, json.aabb.max.y, json.aabb.max.z).length() * 4;
  const maxRenderDistance = Math.max(calculatedMaxRenderDistance, MaxRenderDistanceDefault);

  const fieldOfView = typeof json.camera.fov !== 'undefined' ? json.camera.fov : 70;

  const camera = new THREE.PerspectiveCamera(
    fieldOfView,
    containerWidth(container) / containerHeight(container),
    MinRenderDistanceDefault,
    maxRenderDistance
  );

  const scene = new THREE.Scene();

  let controls: WebGLOrbitControls;

  const mtlLoader = new THREE.MTLLoader();
  const objLoader = new THREE.OBJLoader();

  return new Promise((resolve, reject) => {
    const objAndMtlLoaded = (modelObject: object) => {
      addLightsToScene(scene, camera, useDynamicLighting);
      scene.add(camera);
      scene.add(modelObject);

      const canvas = createCanvas(renderer, container, camera);
  
      controls = initializeControls(renderer, scene, camera, container, json);
      render(renderer, scene, camera);
      animate(controls, renderer, scene, camera);

      resolve(canvas);
    };

    mtlLoader.load(json.mtl, (materials: WebGLMTLs) => {
      materials.preload();
      objLoader
          .setMaterials(materials)
          .load(json.obj, objAndMtlLoaded, undefined, reject);
    }, undefined, reject);
  });
};

export default {
  loadObjAndMtl3D
};
