import THREE, { WebGLScene, WebGLCamera } from 'THREE';

// Some weird dependency system problems need to be bypassed
const THREE = window.THREE as unknown as THREE;

const addLightsToScene = (scene: WebGLScene, camera: WebGLCamera, useDynamicLighting: boolean) => {
  if (useDynamicLighting) {
    const ambient = new THREE.AmbientLight(0x444444);
    camera.add(ambient);

    const keylight = new THREE.DirectionalLight(0xd4d4d4);
    keylight.target = camera;
    keylight.position.set(-7.5, 0.5, -6.0).normalize();
    camera.add(keylight);

    const fillLight = new THREE.DirectionalLight(0xacacac);
    fillLight.target = camera;
    fillLight.position.set(20.0, 4.0, -0).normalize();
    camera.add(fillLight);

    const rimLight = new THREE.DirectionalLight(0xacacac);
    rimLight.target = camera;
    rimLight.position.set(0, 1, 1).normalize();
    camera.add(rimLight);
  } else {
    const ambient = new THREE.AmbientLight(0x878780);
    scene.add(ambient);

    const sunLight = new THREE.DirectionalLight(0xacacac);
    sunLight.position.set(-0.671597898, 0.671597898, 0.312909544).normalize();
    scene.add(sunLight);

    const backLight = new THREE.DirectionalLight(0x444444);
    const backLightPos = new THREE.Vector3()
      .copy(sunLight.position)
      .negate()
      .normalize(); // inverse of sun direction
    backLight.position.set(backLightPos);
    scene.add(backLight);
  }

  return {
    scene,
    camera
  };
};

export default {
  addLightsToScene
};
